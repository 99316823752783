<template>
    <main class="container">
        <div class="content">
            <div class="content_head">
                <div class="inner">
                    <div class="breadcrumb">
                        <ul>
                            <li><a href="#" class="ico_home" title="Home"></a></li>
                            <li>회사소개</li>
                        </ul>
                    </div>
                    <h2 class="page_title">회사소개</h2>
                </div>
            </div>
            <div class="content_body">
                <div class="inner">
                    <div class="company_section">
                        <span class="label">STORY</span>
                        <strong class="tit">
                            <b>WISE</b> and <b>PURE</b>.
                        </strong>
                        <div class="desc">
                            "너희는 뱀처럼 지혜롭고 비둘기처럼 순결하라"<br><br class="br_767">
                            예수님의 가르침처럼 세상을 밝힐 지혜와 불법과 타협하지 않는 순결함으로 이랜드이노플 1호 사내벤쳐 WISENPURE를 설립했습니다.<br>
                            이랜드 그룹의 1호 사내벤쳐로서 사회적 책임과 중소기업과의 상생을 위해 설립되었으며<br>
                            우리의 지혜와 역량으로 WISE, 중소기업이 데이터로 의사결정하는데 기여하는 순수한 의도로 PURE.<br>
                            중소기업과 함께 동반 성장하겠습니다.
                        </div>
                    </div>
                    <div class="company_section">
                        <span class="label">VISION</span>
                        <strong class="tit">
                            세상 모든 기업이<br> <b>데이터로 의사결정하는 세상</b>.
                        </strong>
                        <div class="desc">
                            세상 모든 기업이 빅데이터라는 기술에 소외되지 않고 함께 누리며 활용하는 세상을 만듭니다.
                        </div>
                    </div>
                    <div class="company_section">
                        <span class="label">MISSION</span>
                        <strong class="tit">
                            중소기업과 상생하여<br>
                            <b>빅데이터로 국가경제에 이바지</b>하는 기업.
                        </strong>
                        <div class="desc">
                            단순히 더 많이 세일즈하기 위한 대상으로 고객을 바라보는 것이 아닌 <br>
                            함께 성장하고 발전하기 위한 대상으로 고객을 바라보며 함께 상생하고 협력하여 선을 이루는 기업입니다.
                        </div>
                    </div>
                    <div class="company_section">
                        <span class="label">PEOPLE</span>
                        <strong class="tit">
                            CEO <b>홍슬기</b>.
                        </strong>
                        <div class="desc">
                            대학시절을 포함하여 오늘에 이르기까지 22년간 빅데이터 일에 종사했고<br>
                            이제는 그동안의 기술력과 역량을 바탕으로 사내벤쳐를 설립하여 중소기업에 선한 영향력을 발위하고자 합니다.<br>
                            <br><br class="br_767">
                            우리나라 기업의 80%가 중소기업이라고 합니다.<br>
                            중소기업은 우리나라 국가산업의 핏줄과 같이 없어서는 안될 귀한 존재입니다.<br>
                            중소기업들이 강해져야 국가 경쟁력이 강해집니다.<br>
                            중소기업들이 데이터로 의사결정하여 올바른 경영전략을 세우고 이를 통해 지속적으로 성장 발전하는 길에 우리 와이즈앤퓨어가 함께 하겠습니다.<br>
                            <br><br class="br_767">
                            많은 관심 부탁드립니다.<br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    name: "company"
}
</script>

<style scoped>

</style>